.c-card {
  width: 100%;
  max-width: 400px;
  background: #fff;
  background: var(--theme-content-section-background-color);
  border: 1px solid #ddd;
  border-radius: 3px;
  overflow: hidden;
  display: block;
  position: relative;
  transition: all 0.4s ease;
  height: 100%;
}
.c-card:hover {
  background: #f6f6f6;
}
.c-card__pic {
  width: 100%;
  height: 240px;
  position: relative;
}
.c-card__pic img {
  width: inherit;
  height: inherit;
  /* background: #eee; */
  object-fit: cover;
}
.c-card__info {
  padding: 16px;
}
.c-card__info__ttl {
  height: 29px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.c-card__info__desc {
  height: 64px;
  font-size: 14px;
  line-height: 1.5;
  color: #aaa;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  margin-top: 8px;
}
